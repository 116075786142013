import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
// Components
import Layout from 'components/Layout'
import InterviewHeader from 'components/Sections/Interview/InterviewHeader'
import InterviewContent from 'components/Sections/Interview/InterviewContent'
// Models
import { IOptionalMetaProps } from 'models/SEO'
import { IInterview } from 'models/Interview'

type InterviewPageProps = {
    interview: IInterview
}
type Context = {
    slug: string
}

const InterviewPage: React.FC<PageProps<InterviewPageProps, Context>> = ({
    data,
}) => {
    const { interview } = data
    const seo: IOptionalMetaProps = {
        title: interview.title,
        description: documentToPlainTextString(
            JSON.parse(interview.excerpt.raw)
        ),
        image: interview.thumbnail.file?.url,
    }

    return (
        <Layout seo={seo}>
            <InterviewHeader interview={interview} />
            <InterviewContent interview={interview} />
        </Layout>
    )
}

export default InterviewPage

export const pageQuery = graphql`
    query InterviewPage($slug: String!) {
        interview: contentfulInterviews(slug: { eq: $slug }) {
            title
            slug
            date
            color
            gradientColor
            textColor
            headerStyle

            excerpt {
                raw
            }

            content {
                raw
                references {
                    ... on ContentfulAsset {
                        contentful_id
                        __typename
                        gatsbyImageData(layout:CONSTRAINED)
                    }
                }
            }

            author {
                name
                username
                avatar {
                    gatsbyImageData(layout:CONSTRAINED)
                }
            }

            thumbnail {
                gatsbyImageData(layout:CONSTRAINED)
                file {
                    url
                }
            }
            source
        }
    }
`
