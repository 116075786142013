import React from 'react'
// Components
import RichText from 'components/UIElements/RichText'
import Share from 'components/UIElements/Share'
// Models
import { IInterview } from 'models/Interview'
// Styles
import * as styles from './InterviewContent.module.css'

type InterviewContentProps = {
    interview: IInterview
}

const InterviewContent: React.FC<InterviewContentProps> = ({ interview }) => {
    const { content, title, color, gradientColor } = interview
    const background = gradientColor
        ? `linear-gradient(${color} 150px, ${gradientColor})`
        : color
    return (
        <main style={{ background }} className={styles.interview_wrapper}>
            <article className={styles.interview}>
                <section className={styles.interview_content}>
                    <RichText content={content} alt={title} />
                    <a
                        href={interview.source}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Link de la entrevista original
                    </a>
                </section>
                <section className={styles.interview_footer}>
                    <Share message={interview.title} />
                </section>
            </article>
        </main>
    )
}

export default InterviewContent
