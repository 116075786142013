import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getFormattedDate } from 'util/date'
// Components
import RichText from 'components/UIElements/RichText'
// Models
import { IInterview } from 'models/Interview'
// Styles
import * as styles from './InterviewHeader.module.css'

type InterviewHeaderProps = {
    interview: IInterview
}

const InterviewHeader: React.FC<InterviewHeaderProps> = ({ interview }) => {
    const {
        headerStyle,
        date,
        excerpt,
        color,
        textColor,
        thumbnail,
        title,
        author,
    } = interview
    const formatted_date = getFormattedDate(date)
    const style = {
        '--main-color-interview': color,
        color: textColor,
    }
    return (
        <header
            className={styles.interview_header}
            data-style={headerStyle[0]}
            style={style}
        >
            <GatsbyImage
                className={styles.interview_thumbnail}
                image={thumbnail.gatsbyImageData}
                alt={title}
                loading="eager"
            />
            <div className={styles.interview_info}>
                <h2 className={styles.interview_title}>{title}</h2>
                <RichText content={excerpt} alt={title} />
                <span className={styles.interview_date}>{formatted_date}</span>
                <span className={styles.interview_translator}>
                    « Traducido por{' '}
                    <Link to={`/${author.username}`}>{author.name}</Link> »
                </span>
            </div>
        </header>
    )
}

export default InterviewHeader
